import type {FC, ReactNode} from 'react';
import {Link, LinkProps} from 'react-router-dom';

type ChildProps = {
  children: ReactNode;
};

function ListItem({children}: ChildProps): ReactNode {
  return (
    <li className="inline-block after:mx-2 after:content-['|'] last:after:content-['']">
      {children}
    </li>
  );
}

const linkClasses = 'text-gray-600i decoration-none';
function GrayRouterLink({children, ...linkProps}: LinkProps): ReactNode {
  return (
    <Link {...linkProps} className={linkClasses}>
      {children}
    </Link>
  );
}

type GrayLinkProps = ChildProps & {
  href: string;
};

function GrayLink({children, href}: GrayLinkProps): ReactNode {
  return (
    <a className={linkClasses} href={href}>
      {children}
    </a>
  );
}

export const Links: FC = () => (
  <ul className="text-center">
    <ListItem>
      <GrayLink href="mailto:cheese@byteburgers.com">
        cheese@byteburgers.com
      </GrayLink>
    </ListItem>
    <ListItem>
      <GrayLink href="https://github.com/mrlaessig">GitHub</GrayLink>
    </ListItem>
    <ListItem>
      <GrayRouterLink to="/tgif">tgif</GrayRouterLink>
    </ListItem>
    <ListItem>
      <GrayRouterLink to="/autocomplete">rn-autocomplete</GrayRouterLink>
    </ListItem>
  </ul>
);
