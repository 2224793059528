import React, {Suspense} from 'react';
import type {FC, ReactNode} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import {Tgif} from './modules/fridayfivepm/components/Tgif';
import {Home} from './modules/home/components/Home';
import {NotFound} from './modules/home/components/NotFound';
import {classNames} from 'common';

const Voellerei = React.lazy(
  () => import('./modules/voellerei/components/Pages/Voellerei'),
);
const AutocompleteSnack = React.lazy(
  () => import('./modules/autocomplete/components/AutocompleteSnack'),
);

function lazyLoad(element: React.ReactNode) {
  return <Suspense fallback={<span>Loading</span>}>{element}</Suspense>;
}

function BackButton(): ReactNode {
  const location = useLocation();
  const navigate = useNavigate();
  const isRoot = location.pathname === '/';
  const canNavigateBack = !isRoot && location.key !== 'default';

  return (
    <button
      onClick={() => navigate(-1)}
      className={classNames(
        'h-10 w-10 rounded-md border border-gray-800 font-bold',
        {hidden: !canNavigateBack},
      )}
    >
      ←
    </button>
  );
}

export const App: FC = () => (
  <div className="flex h-screen flex-col">
    <nav className="p-6 ">
      <BackButton />
    </nav>
    <Routes>
      <Route index element={<Home />} />
      <Route path="/tgif" element={<Tgif />} />
      <Route path="/autocomplete" element={lazyLoad(<AutocompleteSnack />)} />
      <Route path="/voellerei/*" element={lazyLoad(<Voellerei />)} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </div>
);
