// src/classNames/classNames.ts
function classNames(...classes) {
  const cssClass = [];
  for (const className of classes) {
    if (typeof className === "string") {
      cssClass.push(className);
    }
    if (typeof className === "object") {
      Object.entries(className).forEach(
        ([value, condition]) => condition && cssClass.push(value)
      );
    }
  }
  return cssClass.join(" ");
}

// src/components/Center.tsx
import { jsx } from "react/jsx-runtime";
var Center = ({ children, className = "" }) => /* @__PURE__ */ jsx(
  "div",
  {
    className: classNames(
      "h-100 flex h-full flex-col items-center justify-center",
      className
    ),
    children
  }
);

// src/hooks/useLoadScript.ts
import { useEffect, useState } from "react";
function useLoadScript(src) {
  const [isLoading, setIsLoaded] = useState(true);
  useEffect(() => {
    const sinScript = document.createElement("script");
    sinScript.src = src;
    sinScript.onload = () => setIsLoaded(false);
    document.body.appendChild(sinScript);
    return () => {
      document.body.removeChild(sinScript);
    };
  });
  return { isLoading };
}
export {
  Center,
  classNames,
  useLoadScript
};
