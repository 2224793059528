import type {FC} from 'react';

import {Burger} from './Burger';
import {Center} from 'common';
import {Links} from './Links';

export const Home: FC = () => (
  <>
    <Center>
      <section className="flex flex-1 items-center justify-center">
        <Burger />
      </section>
      <footer className="pb-16">
        <Links />
      </footer>
    </Center>
  </>
);
