export interface Time {
  readonly hours: number;
  readonly minutes: number;
  readonly seconds: number;
}

export class Countdown {
  readonly end;
  readonly diff;

  constructor(end: Date, start: Date = new Date()) {
    this.end = end;
    this.diff = this.end.getTime() - start.getTime();
  }

  public static tgif(): Countdown {
    const end = new Date();
    const isFriday = end.getDay() - 5 === 0;
    const pastFive =
      (end.getHours() === 17 && end.getMinutes() > 0) || end.getHours() > 17;

    if (isFriday && pastFive) {
      end.setDate(end.getDate() + 7);
    } else {
      end.setDate(end.getDate() + ((7 + 5 - end.getDay()) % 7));
    }

    end.setHours(17, 0, 0, 0);
    return new Countdown(end);
  }

  get time(): Time {
    return {
      hours: Math.floor(this.diff / 1000 / 60 / 60),
      minutes: Math.floor((this.diff / 1000 / 60) % 60),
      seconds: Math.floor((this.diff / 1000) % 60),
    };
  }
}
