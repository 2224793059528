import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {App} from './App';
import './index.css';

function main() {
  const domNode = document.getElementById('root');
  if (domNode === null) {
    return;
  }

  const root = ReactDOM.createRoot(domNode);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>,
  );
}

main();
