import type {FC} from 'react';

import {useLoadScript} from 'common';

interface Props {
  text?: string;
}

export const Burger: FC<Props> = ({text = 'Byteburgers'}) => {
  const {isLoading} = useLoadScript('/sin.js');

  if (isLoading) {
    return null;
  }

  return (
    <div className="rotate-30 hover:animate-wiggle">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        height="85"
        width="300"
      >
        <path
          d="M0 80 C40 0, 265 0, 295 80 Z"
          fill="transparent"
          stroke="#000000"
          id="bunTop"
        />
      </svg>
      <h1 className="text-center text-2xl font-bold">{text}</h1>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="300"
        id="bottomBuger"
      >
        <path id="sin" stroke="black" fill="transparent" />
        <path
          d="M5 45 L295 45 Q304 60 295 75 L5 75 Q-4 60 5 45"
          stroke="black"
          fill="transparent"
          id="bunBottom"
        />
      </svg>
    </div>
  );
};
