import type {ReactNode} from 'react';

import {Pad} from '../Pad';

type ClockDigitPros = {
  unit: string;
  value: number;
};

export function ClockDigit({unit, value}: ClockDigitPros): ReactNode {
  const digits = String(value).length;
  return (
    <div>
      <span
        className={`inline-block ${digits > 2 ? 'w-9' : 'w-6'} font-bold`}
        title={unit}
      >
        <Pad input={value} />
      </span>
      <span className="inline-block px-1 font-light capitalize text-gray-600">
        {unit}
      </span>
    </div>
  );
}
