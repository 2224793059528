import {useEffect, useState, FC} from 'react';

import {Countdown as CountdownModel} from '../../models/Countdown';
import {ClockDigit} from './ClockDigit';

export const Tgif: FC = () => {
  const [
    {
      time: {hours, minutes, seconds},
    },
    setCountdown,
  ] = useState<CountdownModel>(CountdownModel.tgif());

  useEffect(() => {
    const updateTimes = () => setCountdown(CountdownModel.tgif());
    const interval = setInterval(updateTimes, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="flex h-full flex-col items-center justify-center">
      <h2 className="my-10 text-center text-4xl font-bold">
        Time till Friday 5pm:
      </h2>
      <div className="rounded-md border border-black p-4">
        <div className="flex text-xl">
          <ClockDigit value={hours} unit="hours" />
          <ClockDigit value={minutes} unit="minutes" />
          <ClockDigit value={seconds} unit="seconds" />
        </div>
      </div>
    </div>
  );
};
